import React from 'react';
import { ScreenWrapper } from './components/ScreenWrapper';

function App() {
  return (
      <ScreenWrapper />
  );
}

export default App;
